import './App.css';
import Header from './components/header/header.component';
import { createTheme, ThemeProvider, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDarkMode } from 'usehooks-ts';
import useUserSession from './hooks/auth/useAccessToken';
import RoutesComponent from './navigation/routes.component';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authService from './services/auth/auth.service';

const App = () => {
  const { isDarkMode, toggle } = useDarkMode();
  const theme = useMemo(
    () =>
      isDarkMode
        ? createTheme({
            palette: {
              mode: 'dark',
            },
          })
        : createTheme({
            palette: {
              mode: 'light',
            },
          }),
    [isDarkMode]
  );
  const [session, setSession] = useUserSession();
  const isLoggedIn = !!session;

  const handleLogout = async () => {
    setSession(null);
    await authService.logout();
  };

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer
          position="top-center"
          theme={isDarkMode ? 'dark' : 'light'}
        />
        <div className="App">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              height: '100%',
            }}
          >
            {isLoggedIn && (
              <Header
                theme={isDarkMode ? 'dark' : 'light'}
                onChangeTheme={toggle}
                onLogout={handleLogout}
              />
            )}
            <RoutesComponent
              user={session}
              onLogin={setSession}
              onInvalidSession={() => setSession(null)}
            />
          </Box>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
