import {
  DataGrid,
  GridCallbackDetails,
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { DocumentSegment } from '../../../models/parser/parsed.document.result.model';
import { renderCellExpand } from '../../grid/expanded.cell.component';

interface ParserResultColumn {
  field: string;
  title: string;
  width?: number;
  hide?: boolean;
  renderCell?: (params: GridRenderCellParams) => JSX.Element | undefined;
  valueFormatter?: (params: GridValueFormatterParams) => GridCellValue;
}

interface ParserResultProps<T = DocumentSegment> {
  columns: ParserResultColumn[];
  data: T[];
  comparator?: (a: T, b: T) => number;
  renderer?: (item: T) => JSX.Element;
  showToolbar?: boolean;
  page?: number;
  onPageChange?:
    | ((page: number, details: GridCallbackDetails) => void)
    | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ParserResultI<T = any> = React.FC<ParserResultProps<T>>;

export const ParserResultWidget: ParserResultI = ({
  columns,
  data,
  comparator,
  showToolbar,
  page,
  onPageChange,
}) => {
  const columnsDef: GridColDef[] = columns.map((column) => {
    return {
      field: column.field,
      headerName: column.title,
      hide: column.hide,
      editable: false,
      flex: 1,
      minWidth: column.width === undefined ? 200 : column.width,
      renderCell: column.renderCell || renderCellExpand,
      valueFormatter: column.valueFormatter,
    };
  });

  const rows = (comparator === undefined ? data : data.sort(comparator)).map(
    (item, index) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        id: index,
        ...item,
      };
    }
  );

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columnsDef}
          pageSize={10}
          page={page}
          onPageChange={onPageChange}
          rowsPerPageOptions={[10]}
          checkboxSelection
          disableSelectionOnClick
          components={{
            Toolbar: showToolbar ? GridToolbar : null,
          }}
        />
      </div>
    </div>
  );
};
