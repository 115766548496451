/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Container } from '@mui/material';
import React, { useEffect } from 'react';
import {
  Routes,
  useNavigate,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { User } from '../models/auth/user.model';
import ChangeLog from '../pages/changelog/changelog.page';
import changes from '../pages/changelog/changes';
import Home from '../pages/home/home.page';
import Login from '../pages/login/login.page';
import ProtectedRoute from '../components/routes/protected.route';
import AuthService from '../services/auth/auth.service';
import Annotator from '../pages/annotator/annotator.page';
import Register from '../pages/register/register.page';

export interface RoutesComponentProps {
  user: User | null;
  onLogin: (userSession: User) => void;
  onInvalidSession: () => void;
}

const RoutesComponent = ({
  user,
  onLogin,
  onInvalidSession,
}: RoutesComponentProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user) return;

    const validateSession = async () => {
      const isValidSession = await AuthService.verify();
      if (!isValidSession) onInvalidSession();
    };

    void validateSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const isLoggedIn = !!user;

  return (
    <Container maxWidth="xl" sx={{ height: '70vh', flex: '1' }}>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute condition={isLoggedIn} redirect="/login">
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/annotator"
          element={
            <ProtectedRoute condition={isLoggedIn} redirect="/login">
              <Annotator />
            </ProtectedRoute>
          }
        />
        <Route
          path="/login"
          element={
            <ProtectedRoute condition={!isLoggedIn} redirect="/">
              <Login
                onLogin={(session) => {
                  onLogin(session);
                  navigate('/');
                }}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register"
          element={
            <ProtectedRoute condition={!isLoggedIn} redirect="/">
              <Register
                onRegister={() => {
                  navigate('/login');
                }}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/changelog"
          element={
            <ProtectedRoute condition={isLoggedIn} redirect="/login">
              <ChangeLog items={changes} />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Container>
  );
};

export default RoutesComponent;
