import { FileValidated } from '@dropzone-ui/react';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import ResumeAnnotator from '../../components/annotation/resume.annotator.component';
import { FileDrop } from '../../components/file/file.drop.component';
import { TabCollection } from '../../components/tab/tab.collection.component';
import { ReadDocumentResult } from '../../models/parser/parsed.document.result.model';
import resumeParserService from '../../services/parser/resume.parser.service';
import {
  Annotation,
  ResumeAnnotation,
} from '../../models/annotator/resume.annotation.model';
import annotatorService from '../../services/annotator/annotator.service';
import { toast } from 'react-toastify';
import { AnnotatorProject } from '../../models/annotator/annotator.project.model';
import ResumeAnnotations from '../../components/annotation/resume.annotations.component';

enum AnnotatorTabs {
  MyAnnotations,
  Read,
  Annotate,
}

interface QueuedDocumentData {
  result: ReadDocumentResult;
  filename: string;
}

const Annotator = () => {
  const maxAllowedFiles = 1;

  const [tabSelectionIndex, setTabSelectionIndex] =
    React.useState<AnnotatorTabs>(AnnotatorTabs.MyAnnotations);

  const [inputFiles, setInputFiles] = React.useState<FileValidated[]>([]);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const [queuedDocument, setQueuedDocument] =
    React.useState<QueuedDocumentData | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [project, setProject] = useState<AnnotatorProject | null>(null);
  const [resumeAnnotations, setResumeAnnotations] = useState<
    ResumeAnnotation[]
  >([]);
  const [currentResume, setCurrentResume] = useState<ResumeAnnotation | null>(
    null
  );

  const getReadResult = useCallback(async () => {
    if (!queuedDocument) return;

    console.log(queuedDocument);

    const onError = () => {
      toast(`Unable to read document: ${queuedDocument.filename}`);
      setQueuedDocument(null);
      setShowLoader(false);
    };

    try {
      const response = await resumeParserService.getReadResult(
        queuedDocument.result.id
      );

      if (response.data.status === 'READY') {
        setCurrentResume(
          response.data.data
            ? {
                text: response.data.data.text,
                annotations: [],
                name: queuedDocument.filename,
              }
            : null
        );
        setQueuedDocument(null);
        setTabSelectionIndex(AnnotatorTabs.Annotate);
        setShowLoader(false);
      } else if (response.data.status === 'ERROR') {
        onError();
      } else {
        setQueuedDocument({ ...queuedDocument, result: response.data });
      }
    } catch (_ex: unknown) {
      onError();
    }
  }, [queuedDocument]);

  useEffect(() => {
    if (!queuedDocument) return;

    void debounce(getReadResult, 1000)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queuedDocument]);

  const handleTabChange = (newIndex: number) => {
    setTabSelectionIndex(newIndex);
  };

  const onSubmitFiles = async (files: File[]) => {
    if (files.length < 1) return;

    setQueuedDocument(null);
    setShowLoader(true);
    setCurrentResume(null);

    const file = files[0];

    try {
      const response = await resumeParserService.read(file);
      setQueuedDocument({ filename: file.name, result: response.data });
    } catch (ex: unknown) {
      setShowLoader(false);
    }
  };

  const updateFiles = (incommingFiles: FileValidated[]) => {
    setInputFiles(incommingFiles);
  };

  const removeFile = (id: number | string | undefined) => {
    setInputFiles(inputFiles.filter((x: FileValidated) => x.id !== id));
  };

  const getAnnotations = async () => {
    setIsLoading(true);

    try {
      const result = await annotatorService.getAnnotations();
      setResumeAnnotations(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const onSaveResumeAnnotations = async () => {
    if (!currentResume) return;

    setIsLoading(true);

    try {
      !currentResume.id
        ? await annotatorService.saveAnnotation(currentResume)
        : await annotatorService.updateAnnotation(
            { annotations: currentResume.annotations },
            currentResume.id
          );
      void getAnnotations();
      setTabSelectionIndex(AnnotatorTabs.MyAnnotations);
      setCurrentResume(null);
      setInputFiles([]);
      toast('Resume annotations saved!', { type: 'success' });
    } catch (_err: unknown) {
      toast('Unable to save annotations.', { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getProject = async () => {
      setIsLoading(true);

      try {
        const result = await annotatorService.getProject();
        setProject(result.data);
      } catch (_err: unknown) {
        toast('Unable to retrieve annotator settings.');
      } finally {
        setIsLoading(false);
      }
    };

    void getProject();
  }, []);

  useEffect(() => {
    void getAnnotations();
  }, [project]);

  const onDeleteAnnotation = async (annotation: ResumeAnnotation) => {
    if (!annotation.id) return;

    setIsLoading(true);

    try {
      const result = await annotatorService.deleteAnnotation(annotation.id);
      setResumeAnnotations((prev) =>
        prev.filter((ann) => ann.id !== result.data.id)
      );

      if (result.data.id === currentResume?.id) {
        setCurrentResume(null);
      }

      toast('Successfully deleted annotation!', { type: 'success' });
    } catch (_err: unknown) {
      toast('Unable to delete resume annotation.', { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const onEditAnnotation = (annotation: ResumeAnnotation) => {
    setCurrentResume(annotation);
    setTabSelectionIndex(AnnotatorTabs.Annotate);
    setInputFiles([]);
  };

  const onUpdate = (annotations: Annotation[]) => {
    if (!currentResume) return;

    setCurrentResume({ ...currentResume, annotations });
  };

  const onReset = () => {
    if (!currentResume) return;

    setCurrentResume({ ...currentResume, annotations: [] });
  };

  const loader = (
    <Backdrop open={isLoading}>
      <CircularProgress sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }} />
    </Backdrop>
  );

  return (
    <TabCollection
      selectionIndex={tabSelectionIndex}
      onSelectionChanged={handleTabChange}
      items={[
        {
          label: 'My Annotations',
          disabled: false,
          content: (
            <Box
              sx={{
                height: 'inherit',
              }}
            >
              <ResumeAnnotations
                annotations={resumeAnnotations}
                onDelete={onDeleteAnnotation}
                onEdit={onEditAnnotation}
              />
              {loader}
            </Box>
          ),
        },
        {
          label: 'Read Resume',
          content: (
            <Box
              sx={{
                maxWidth: '50%',
                minWidth: '300px',
                margin: 'auto',
              }}
            >
              <FileDrop
                maxFiles={maxAllowedFiles}
                submitButtonText={'Read'}
                onSubmitFiles={onSubmitFiles}
                isLoading={showLoader}
                onRemoveFile={removeFile}
                onUpdateFiles={updateFiles}
                files={inputFiles}
                loadingIndicator={
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress
                      size={45}
                      color="inherit"
                      variant="indeterminate"
                    />
                  </Box>
                }
              />
            </Box>
          ),
        },
        {
          label: 'Annotate',
          disabled: currentResume === null,
          content: currentResume && project && (
            <Box
              sx={{
                height: 'inherit',
              }}
            >
              <ResumeAnnotator
                annotations={currentResume.annotations}
                text={currentResume.text}
                labels={project.labels}
                onUpdate={onUpdate}
                onSave={onSaveResumeAnnotations}
                onReset={onReset}
              />
              {loader}
            </Box>
          ),
        },
      ]}
    />
  );
};

export default Annotator;
