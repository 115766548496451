import { getContrastRatio } from '@mui/system';

export const stringToColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};

export const getContrastColor = (hexcolor: string) => {
  const whiteContrast = getContrastRatio('#fff', hexcolor);
  return whiteContrast < 2.0 ? '#000' : '#fff';
};
