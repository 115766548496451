import { Navigate } from 'react-router-dom';

export interface ProtectedRouteProps {
  condition: boolean;
  children: JSX.Element;
  redirect: string;
}

const ProtectedRoute = ({
  condition,
  children,
  redirect,
}: ProtectedRouteProps): JSX.Element => {
  if (!condition) {
    return <Navigate to={redirect} replace />;
  }

  return children;
};

export default ProtectedRoute;
