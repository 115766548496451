import { ChangeLogItemDef } from './changelog.page';

const changes: ChangeLogItemDef[] = [
  {
    date: '10/11/2022',
    features: [{ short: 'Updated segmentation model' }],
  },
  {
    date: '08/15/2022',
    features: [
      { short: 'Improved Grants parsing' },
      { short: 'Improved handling of docx' },
      { short: 'Added labels "top content" and "read error" to annotator' },
    ],
  },
  {
    date: '06/15/2022',
    features: [
      { short: 'Added initial support for professional honors awards.' },
      { short: 'Preprocessing pipeline improvements.' },
    ],
  },
  {
    date: '06/03/2022',
    features: [
      { short: 'Updated dataset for education degrees & institutions.' },
      { short: 'Improved date range handling.' },
      { short: 'General fixes & improvements.' },
    ],
  },
  {
    date: '05/15/2022',
    features: [{ short: 'Improved segment overlap resolution.' }],
    fixes: [{ short: 'Fixed an issue where the segment text was truncated.' }],
  },
  {
    date: '05/04/2022',
    features: [
      { short: 'Added new segmentation model' },
      { short: 'General fixes & improvements' },
    ],
  },
  {
    date: '04/14/2022',
    features: [
      {
        short:
          'Added support Department & Title as part of the Administrative Positions segment',
      },
      {
        short:
          'Added support for Academic Unit & Title as part of the Appointments segment',
      },
      {
        short:
          'Added support for CertificationOrganization & Specialty as part of the Board Certifications segment',
      },
      {
        short:
          'Augmented the Department & Institution data for the Education segment with the dataset provided by Yale',
      },
      { short: 'Added rules for handling a broader array of segment layouts' },
      { short: 'Small performance improvements' },
    ],
    issues: [
      {
        short: 'Entity has an incorrect label/value',
        description:
          'We are still working on cleaning up the datasets being used for the entity identification, so these issues should be fixed once this task is done.',
      },
      {
        short: 'Segment rows are not properly identified',
        description:
          'This will improve as more rules are added to handle differences in the layout of segments(e.g: the order of entities)',
      },
      {
        short: 'Segment boundaries are not properly identified',
        description: 'This will improve as the segmentation model improves',
      },
    ],
  },
  {
    date: '04/11/2022',
    fixes: [
      {
        short:
          'Fixed an issue where large resumes were not parsed by the model.',
      },
    ],
  },
];

export default changes;
