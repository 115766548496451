import { Dropzone, FileItem, FileValidated } from '@dropzone-ui/react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

interface FileDropProps {
  onSubmitFiles: (files: File[]) => void;
  onUpdateFiles: (files: FileValidated[]) => void;
  onRemoveFile: (id: number | string | undefined) => void;
  submitButtonText: string;
  isLoading: boolean;
  files: FileValidated[];
  loadingIndicator?: React.ReactNode;
  maxFiles?: number;
}

export const FileDrop = ({
  onSubmitFiles,
  onUpdateFiles,
  onRemoveFile,
  submitButtonText,
  isLoading,
  files,
  loadingIndicator,
  maxFiles,
}: FileDropProps) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(
    () => setIsButtonDisabled(files.filter((file) => file.valid).length === 0),
    [files]
  );

  const handleSubmitFiles = () => {
    const nativeFiles = files
      .filter((file) => file.valid)
      .map((file) => file.file);

    onSubmitFiles(nativeFiles);
  };

  return (
    <div>
      <Box sx={{ mb: 1 }}>
        <Dropzone
          onChange={onUpdateFiles}
          value={files}
          maxFiles={maxFiles ? maxFiles : 15}
          accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain"
          footer={false}
          header={false}
          minHeight={'200px'}
          maxHeight={'300px'}
        >
          {files.map((file: FileValidated) => (
            <FileItem {...file} onDelete={onRemoveFile} key={file.id} info />
          ))}
        </Dropzone>
      </Box>
      <LoadingButton
        fullWidth
        sx={{ height: '55px' }}
        loading={isLoading}
        disabled={isButtonDisabled}
        variant="contained"
        onClick={handleSubmitFiles}
        loadingIndicator={
          loadingIndicator ? (
            loadingIndicator
          ) : (
            <CircularProgress color="inherit" size={16} />
          )
        }
      >
        {submitButtonText}
      </LoadingButton>
    </div>
  );
};
