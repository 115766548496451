import React, { useEffect, useState, useCallback } from 'react';
import resumeParser from '../../services/parser/resume.parser.service';
import { Box } from '@mui/system';
import { ParserResultWidget } from './common/parser.result.component';
import { FileDrop } from '../file/file.drop.component';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  AggregationResult,
  DocumentSegment,
  ParsedDocumentResult,
} from '../../models/parser/parsed.document.result.model';
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { FileValidated } from '@dropzone-ui/react';
import { TabCollection } from '../tab/tab.collection.component';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ParserAggregationResult from './aggregation/parser.aggregation.component';
import { ParsedResumeFile } from '../../models/parser/parsed.resume.file.model';
import debounce from 'lodash.debounce';

enum ParserTabs {
  Scan = 0,
  Segments = 1,
}

interface QueuedDocumentData {
  result: ParsedDocumentResult;
  filename: string;
}

const RESULT_CHECK_INTERVAL_MS = 1000;

export const ResumeParserWidget = () => {
  const maxAllowedFiles = 10;
  const [resumeData, setResumeData] = React.useState<ParsedResumeFile[]>([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [tabSelectionIndex, setTabSelectionIndex] = React.useState<ParserTabs>(
    ParserTabs.Scan
  );
  const [inputFiles, setInputFiles] = React.useState<FileValidated[]>([]);
  const [currentDataPage, setCurrentDataPage] = React.useState(0);
  const [accordionExpanded, setAccordionExpanded] = React.useState<
    string | false
  >(false);

  const [aggregation, setAggregation] = useState<AggregationResult[]>([]);
  const [currentSegment, setCurrentSegment] = useState<string>('');

  const [queuedDocuments, setQueuedDocuments] = useState<QueuedDocumentData[]>(
    []
  );

  const handleAccordionChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setAccordionExpanded(isExpanded ? panel : false);
      setAggregation([]);
      setCurrentSegment('');
    };

  const updateFiles = (incommingFiles: FileValidated[]) => {
    setInputFiles(incommingFiles);
  };

  const removeFile = (id: number | string | undefined) => {
    setInputFiles(inputFiles.filter((x: FileValidated) => x.id !== id));
  };

  const handleReceiveParsedResumeResult = (
    result: ParsedDocumentResult,
    filename: string
  ) => {
    setResumeData((oldResumeData) => [
      ...oldResumeData,
      { filename: filename, data: result.data },
    ]);
  };

  const handleAllResumesParsed = () => {
    setShowLoader(false);
    setTabSelectionIndex(ParserTabs.Segments);
  };

  const submitFile = async (file: File): Promise<QueuedDocumentData | null> => {
    try {
      const result = await resumeParser.parse(file);
      return { filename: file.name, result: result.data };
    } catch (_) {
      handleResponseError(file.name);
      return null;
    }
  };

  const getResult = async (
    document: QueuedDocumentData
  ): Promise<QueuedDocumentData | null> => {
    try {
      const result = await resumeParser.getResult(document.result.id);
      return { ...document, result: result.data };
    } catch (_) {
      return null;
    }
  };

  const handleQueuedDocuments = useCallback(async () => {
    const items = await Promise.all(
      queuedDocuments.map((doc) => getResult(doc))
    );

    items.forEach((item) => {
      if (item?.result && item.result && item.result.status === 'READY') {
        handleReceiveParsedResumeResult(item.result, item.filename);
      }
    });

    const left = items.filter(
      (item): item is QueuedDocumentData =>
        item !== null && item.result.status === 'QUEUED'
    );

    setQueuedDocuments(left);
    if (left.length === 0) {
      handleAllResumesParsed();
    }
  }, [queuedDocuments]);

  const onSubmitFiles = async (files: File[]) => {
    setResumeData([]);
    setAggregation([]);
    setShowLoader(true);

    const result = await Promise.all(files.map((file) => submitFile(file)));
    const validItems = result.filter(
      (item): item is QueuedDocumentData => item !== null
    );
    setQueuedDocuments(validItems);
  };

  useEffect(() => {
    if (queuedDocuments.length === 0) return;

    void debounce(handleQueuedDocuments, RESULT_CHECK_INTERVAL_MS)();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queuedDocuments]);

  const handleResponseError = (filename: string) => {
    setResumeData((oldResumeData) => [
      ...oldResumeData,
      {
        filename: filename,
        error: 'The server was unable to parse the given resume.',
      },
    ]);
  };

  const handleTabChange = (newIndex: number) => {
    setTabSelectionIndex(newIndex);
  };

  const handleOpenAggregation = (
    segmentName: string,
    aggregated: AggregationResult[]
  ) => {
    setAggregation(aggregated);
    setCurrentSegment(segmentName);
  };

  const onCloseAggregation = () => {
    setAggregation([]);
    setCurrentSegment('');
  };

  const parserResult = (data: ParsedResumeFile) =>
    data.data && (
      <ParserResultWidget
        columns={[
          { title: 'Segment', field: 'name', width: 200 },
          { title: 'Score', field: 'score', width: 120 },
          {
            title: 'Text',
            field: 'text',
            width: 400,
          },
          { title: 'Start', field: 'start', width: 100 },
          { title: 'End', field: 'end', width: 100 },
          {
            title: 'Entities',
            field: 'aggregated',
            width: 120,
            valueFormatter: (
              params: GridValueFormatterParams<AggregationResult[]>
            ) => params.value.length,
            renderCell: (params: GridRenderCellParams<AggregationResult[]>) => (
              <Button
                disabled={!params.value || params.value.length === 0}
                variant="outlined"
                onClick={() => {
                  const segmentName = params.getValue(
                    params.id,
                    'name'
                  ) as string;
                  if (segmentName && params.value) {
                    handleOpenAggregation(segmentName, params.value);
                  }
                }}
              >
                <Typography>SHOW({params.value?.length ?? 0})</Typography>
              </Button>
            ),
          },
        ]}
        data={data.data.segments}
        comparator={(a: DocumentSegment, b: DocumentSegment) =>
          a.start - b.start
        }
        showToolbar={true}
        page={currentDataPage}
        onPageChange={(page, _) => setCurrentDataPage(page)}
      />
    );

  return (
    <div>
      <TabCollection
        selectionIndex={tabSelectionIndex}
        onSelectionChanged={handleTabChange}
        items={[
          {
            label: 'Scan Resume',
            content: (
              <Box sx={{ maxWidth: '50%', minWidth: '300px', margin: 'auto' }}>
                <FileDrop
                  maxFiles={maxAllowedFiles}
                  submitButtonText={'Parse'}
                  onSubmitFiles={onSubmitFiles}
                  isLoading={showLoader}
                  onRemoveFile={removeFile}
                  onUpdateFiles={updateFiles}
                  files={inputFiles}
                  loadingIndicator={
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        size={45}
                        color="inherit"
                        variant="indeterminate"
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          color="text.secondary"
                        >{`${resumeData.length}/${Math.min(
                          inputFiles.length,
                          maxAllowedFiles
                        )}`}</Typography>
                      </Box>
                    </Box>
                  }
                />
              </Box>
            ),
          },
          {
            label: 'Results',
            disabled: resumeData.length === 0,
            content: resumeData.map((data, idx) => {
              return (
                <Accordion
                  key={`result-${idx}`}
                  expanded={accordionExpanded === `result-${idx}`}
                  onChange={handleAccordionChange(`result-${idx}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="result-accordion"
                  >
                    <Typography sx={{ pr: 1 }}>{data.filename}</Typography>
                    {data.error && <ErrorIcon color="error" />}
                    {data.data && <CheckCircleIcon color="success" />}
                  </AccordionSummary>
                  <AccordionDetails>
                    {data.data === undefined ? (
                      <Typography>{data.error}</Typography>
                    ) : accordionExpanded !==
                      `result-${idx}` ? null : aggregation.length > 0 ? (
                      <ParserAggregationResult
                        results={aggregation}
                        segment={currentSegment}
                        onBackButtonClicked={onCloseAggregation}
                      />
                    ) : (
                      parserResult(data)
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            }),
          },
        ]}
      />
    </div>
  );
};
