import axios, { AxiosResponse } from 'axios';
import { BACKEND_SERVER_URL } from '../config/config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Response<T = any, D = any> = AxiosResponse<T, D>;

export default axios.create({
  baseURL: BACKEND_SERVER_URL,
  headers: {
    'Content-type': 'application/json',
  },
  withCredentials: true,
});
