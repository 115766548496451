import axios from 'axios';
import { User } from '../../models/auth/user.model';
import http from '../common';

class AuthService {
  async login(email: string, password: string): Promise<User> {
    const result = await http.post<User>('/auth/login', { email, password });
    return result.data;
  }

  async register(
    email: string,
    password: string,
    invitationCode: string
  ): Promise<User> {
    const result = await http.post<User>('/users', {
      email,
      password,
      invitation: invitationCode,
    });
    return result.data;
  }

  async logout() {
    return http.post('/auth/logout');
  }

  async verify(): Promise<boolean> {
    try {
      await http.get<User>('/auth/validate');
      return true;
    } catch (ex) {
      if (axios.isAxiosError(ex)) {
        return !(ex.response?.status === 401);
      }
      return true;
    }
  }
}

export default new AuthService();
