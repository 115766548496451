import {
  CreateResumeAnnotationDto,
  UpdateResumeAnnotationDto,
} from '../../dtos/resume.annotation.dto';
import { AnnotatorProject } from '../../models/annotator/annotator.project.model';
import { ResumeAnnotation } from '../../models/annotator/resume.annotation.model';
import http, { Response } from '../common';

class AnnotatorService {
  private project;

  constructor(project: string) {
    this.project = project;
  }

  async getProject(): Promise<Response<AnnotatorProject>> {
    const result = await http.get<AnnotatorProject>(
      `annotator/project/${this.project}`
    );
    return result;
  }

  async saveAnnotation(
    annotation: CreateResumeAnnotationDto
  ): Promise<Response<ResumeAnnotation>> {
    const result = await http.post<ResumeAnnotation>(
      `annotator/project/${this.project}/annotations`,
      annotation
    );
    return result;
  }

  async getAnnotations(): Promise<Response<ResumeAnnotation[]>> {
    const result = await http.get<ResumeAnnotation[]>(
      `annotator/project/${this.project}/annotations`
    );
    return result;
  }

  async updateAnnotation(
    dto: UpdateResumeAnnotationDto,
    id: string
  ): Promise<Response<ResumeAnnotation>> {
    const result = await http.put<ResumeAnnotation>(
      `annotator/project/${this.project}/annotations/${id}`,
      dto
    );
    return result;
  }

  async deleteAnnotation(id: string): Promise<Response<ResumeAnnotation>> {
    const result = await http.delete<ResumeAnnotation>(
      `annotator/project/${this.project}/annotations/${id}`
    );
    return result;
  }
}

export default new AnnotatorService('yale');
