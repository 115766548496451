import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Box } from '@mui/system';

export interface LabelData {
  value: string;
  color: string;
}

interface LabelsProps {
  labels: LabelData[];
  onClick: (label: LabelData) => void;
}

const Labels = (props: LabelsProps) => {
  const { labels } = props;

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
        alignItems: 'center',
        userSelect: 'none',
      }}
    >
      <List
        sx={{
          maxHeight: '100%',
          overflow: 'auto',
          flex: '1 1 0',
          userSelect: 'none',
        }}
      >
        {labels.map((label) => (
          <ListItem key={label.value}>
            <ListItemButton
              onClick={() => props.onClick(label)}
              sx={{
                borderLeft: `4px solid ${label.color}`,
                userSelect: 'none',
              }}
            >
              <ListItemText
                primary={label.value.replace('_', ' ')}
                primaryTypographyProps={{
                  fontWeight: 'medium',
                  variant: 'body2',
                  textTransform: 'capitalize',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Labels;
