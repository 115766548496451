import { AxiosResponse } from 'axios';
import {
  ParsedDocumentResult,
  QueuedDocumentResult,
  ReadDocumentResult,
} from '../../models/parser/parsed.document.result.model';
import http from '../common';

class ResumeParserService {
  parse(file: File): Promise<AxiosResponse<QueuedDocumentResult>> {
    const formData = new FormData();
    formData.append('file', file);

    return http.post<QueuedDocumentResult>('/parser/parse', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getResult(id: string): Promise<AxiosResponse<ParsedDocumentResult>> {
    return http.get<ParsedDocumentResult>(`/parser/result/${id}`);
  }

  getReadResult(id: string): Promise<AxiosResponse<ReadDocumentResult>> {
    return http.get<ReadDocumentResult>(`/parser/result/${id}`);
  }

  read(file: File): Promise<AxiosResponse<QueuedDocumentResult>> {
    const formData = new FormData();
    formData.append('file', file);

    return http.post<QueuedDocumentResult>('/parser/read', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new ResumeParserService();
