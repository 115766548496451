/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Delete, Edit } from '@mui/icons-material';
import { IconButton, Paper } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { ResumeAnnotation } from '../../models/annotator/resume.annotation.model';
export interface ResumeAnnotationsProps {
  annotations: ResumeAnnotation[];
  onDelete?: (annotation: ResumeAnnotation) => void;
  onEdit?: (annotation: ResumeAnnotation) => void;
}

const ResumeAnnotations = (props: ResumeAnnotationsProps) => {
  const resumeAnnotations = props.annotations;

  const rows = resumeAnnotations
    .sort((a, b) => a.created?.localeCompare(b.created ?? '') ?? 0)
    .reverse()
    .map((item, index) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        id: index,
        ...item,
      };
    });

  const itemActions = (annotation: ResumeAnnotation) => (
    <>
      <IconButton onClick={() => props.onEdit?.(annotation)}>
        <Edit />
      </IconButton>
      <IconButton onClick={() => props.onDelete?.(annotation)}>
        <Delete />
      </IconButton>
    </>
  );

  const columnsDef: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      hide: false,
      editable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'created',
      headerName: 'Date',
      hide: false,
      editable: false,
      flex: 1,
      minWidth: 200,
      valueFormatter: (param: GridValueFormatterParams<string>) =>
        new Date(param.value).toLocaleString(),
    },
    {
      field: 'id',
      headerName: 'Actions',
      hide: false,
      editable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (param: GridRenderCellParams<string>) =>
        itemActions(resumeAnnotations.find((ann) => ann.id === param.value)!),
    },
  ];

  return (
    <Paper elevation={4}>
      <DataGrid
        sx={{ padding: 1 }}
        autoHeight
        rows={rows}
        columns={columnsDef}
        pageSize={10}
      ></DataGrid>
    </Paper>
  );
};

export default ResumeAnnotations;
