import { ReactEditor, RenderLeafProps } from 'slate-react';
import { BaseEditor, BaseRange } from 'slate';
import styled from '@emotion/styled';
import { alpha, IconButton } from '@mui/material';
import { Clear, HighlightAlt } from '@mui/icons-material';
import { LabelData } from './labels/labels.component';
import { Annotation } from '../../models/annotator/resume.annotation.model';

export type CustomEditor = BaseEditor & ReactEditor;

export type ElementType = 'paragraph' | 'heading' | 'annotation';

export type BaseElement = {
  type: ElementType;
};

export type ParagraphElement = BaseElement & {
  type: 'paragraph';
  children: CustomText[];
};

export type CustomElement = ParagraphElement;

export interface AnnotationData {
  range: BaseRange;
  label: LabelData;
}

export type FormattedText = {
  text: string;
  color?: string;
  annotation?: Annotation;
};

export type CustomText = FormattedText;

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const AnnotationActionsContainer = styled.div`
  display: block;
  text-align: right;
`;

export interface AnnotationLeafProps {
  renderLeafProps: RenderLeafProps;
  onRemove?: () => void;
  onSelect?: () => void;
  selected?: boolean;
}

export const AnnotationLeaf = (props: AnnotationLeafProps) => {
  if (
    !props.renderLeafProps.leaf.color ||
    !props.renderLeafProps.leaf.annotation
  ) {
    return <></>;
  }

  return (
    <span
      {...props.renderLeafProps.attributes}
      style={{
        border: `2px solid ${props.renderLeafProps.leaf.color}`,
        padding: '3px',
        borderRadius: '4px',
        margin: '2px',
        display: 'inline-block',
        transition: 'border ease 0.2s',
        background: props.selected
          ? alpha(props.renderLeafProps.leaf.color, 0.1)
          : 'none',
      }}
    >
      <AnnotationActionsContainer>
        <IconButton onClick={props.onSelect} size="small">
          <HighlightAlt />
        </IconButton>
        <IconButton onClick={props.onRemove} size="small">
          <Clear />
        </IconButton>
      </AnnotationActionsContainer>
      {props.renderLeafProps.children}
    </span>
  );
};
