import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { TabPanel } from './tab.panel.component';

interface TabItem {
  label: string;
  content: React.ReactNode;
  disabled?: boolean;
  visible?: boolean;
}

interface TabCollectionProps {
  items: TabItem[];
  selectionIndex: number;
  onSelectionChanged: (selectionIndex: number) => void;
}

export const TabCollection = (props: TabCollectionProps) => {
  const { items, selectionIndex, onSelectionChanged, ...other } = props;

  return (
    <Box
      sx={{
        width: '100%',
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
      }}
      {...other}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={selectionIndex}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          onChange={(_, value) => onSelectionChanged(value)}
        >
          {items.map(
            (item, idx) =>
              (item.visible || item.visible === undefined) && (
                <Tab label={item.label} key={idx} disabled={item.disabled} />
              )
          )}
        </Tabs>
      </Box>
      {items.map(
        (item, idx) =>
          (item.visible || item.visible === undefined) && (
            <TabPanel
              value={selectionIndex}
              index={idx}
              key={idx}
              style={{
                height: 'inherit',
              }}
            >
              {item.content}
            </TabPanel>
          )
      )}
    </Box>
  );
};
