import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import { AggregationResult } from '../../../models/parser/parsed.document.result.model';
import { ParserResultWidget } from '../common/parser.result.component';

interface ParserAggregationResultProps {
  segment: string;
  results: AggregationResult[];
  onBackButtonClicked: () => void;
}

interface AggregatedSegment {
  [key: string]: string;
}

const entitiesToHideByDefault = ['DATE', 'LOC', 'ORG'];

const ParserAggregationResult = (props: ParserAggregationResultProps) => {
  const aggregation: AggregatedSegment[] = props.results.map((item) =>
    Object.fromEntries(item.entities.map((val) => [val.name, val.value]))
  );

  const getAggregationColumns = () => {
    const columns = new Set<string>();
    aggregation.forEach((item) =>
      Object.keys(item).forEach((key) => columns.add(key))
    );
    return Array.from(columns);
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          mb: 1,
          gap: 1,
        }}
      >
        <IconButton onClick={props.onBackButtonClicked}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6">{props.segment}</Typography>
      </Box>
      <ParserResultWidget
        columns={getAggregationColumns().map((key) => {
          return {
            title: key,
            field: key,
            width: 150,
            valueFormatter: (params: GridValueFormatterParams) =>
              params.value === undefined || params.value === null
                ? 'N/A'
                : (params.value as string),
            hide: entitiesToHideByDefault.includes(key),
          };
        })}
        data={aggregation}
        showToolbar={true}
      />
    </div>
  );
};

export default ParserAggregationResult;
