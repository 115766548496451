import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { User } from '../../models/auth/user.model';
import AuthService from '../../services/auth/auth.service';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';

export interface LoginProps {
  onLogin: (session: User) => void;
}

const Login = (props: LoginProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const handleLogin = async () => {
    try {
      const result = await AuthService.login(email, password);
      props.onLogin(result);
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response?.status === 401) {
        toast('Invalid username or password.', { type: 'error' });
      } else {
        toast('Unable to login.', { type: 'error' });
      }
    }
  };

  const onEmailChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEmail(ev.target.value);
  };

  const onPasswordChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPassword(ev.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 10,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          width: '30em',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          padding: 3,
        }}
      >
        <Typography
          fontFamily="sans-serif"
          alignSelf={'center'}
          variant="h4"
          mb={2}
          noWrap
        >
          Ordinal
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          onSubmit={(ev: React.FormEvent) => ev.preventDefault()}
        >
          <TextField
            autoFocus
            required
            id="email"
            name="email"
            label="Email"
            autoComplete="email"
            variant="outlined"
            value={email}
            onChange={onEmailChange}
            fullWidth
          />
          <TextField
            required
            autoComplete="current-password"
            variant="outlined"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={onPasswordChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle-password-visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            variant="contained"
            onClick={(ev) => {
              ev.preventDefault();
              void handleLogin();
            }}
          >
            Login
          </Button>
          <Box
            sx={{
              mt: 1,
              gap: 0.5,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography color="text.secondary" variant="body2">
              Don't have an account?
            </Typography>
            <Typography
              color="primary"
              variant="body2"
              component={Link}
              to="/register"
              sx={{ textDecoration: 'none' }}
            >
              Sign Up
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
