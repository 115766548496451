import { useState } from 'react';
import { User } from '../../models/auth/user.model';

const useUserSession = (
  key = 'user_session'
): [User | null, (session: User | null) => void] => {
  const loadSession = (): User | null => {
    const token = localStorage.getItem(key);
    if (!token) return null;
    return JSON.parse(token) as User;
  };

  const [session, setSession] = useState<User | null>(loadSession());

  const saveSession = (userSession: User | null) => {
    if (!userSession) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(userSession));
    }
    setSession(userSession);
  };

  return [session, saveSession];
};

export default useUserSession;
